<template>
  <div>
    <div class="big-box">
      <!-- 充值金额 -->
      <div class="sum">
        <div class="text">充值金额</div>
        <div class="input-cz">
          <van-field @blur="moneyBlur" v-model="number" type="number" label="￥" />
          <span class="max-num" @click="maxPay">最多金额</span>
        </div>
        <div class="tips">单笔最多可充￥{{maxSum}}</div>
      </div>
      <!-- 支付方式-->
      <div class="payment">
        <!-- 微信支付宝 -->
        <div class="left">
          <div>
            <span class="alipay"></span>
            <span>支付宝支付</span>
          </div>
          <div v-if="isShow">
            <span class="wechat"></span>
            <span>微信宝支付</span>
          </div>
        </div>
        <!-- 单选 -->
        <div class="right">
          <van-radio-group v-model="payment">
            <van-radio name="1"></van-radio>
            <van-radio name="2" v-if="isShow"></van-radio>
          </van-radio-group>
        </div>
      </div>
      <!-- 充值 -->
      <van-button type="default" @click="handlerPay" class="paymen-btn" :disabled="btn">充值</van-button>
    </div> 
  </div>
</template>

<script>
  export default {
    name:'Payment',
    data(){
      return {
        payment: '1', //1 支付宝 2微信
        number:'',//充值金额
        // btnShow: false
        //最多可充值金额
        maxSum:1000,
        isShow: true
      }
    },
    mounted(){
      window.iosFn = this.ocToJs
      window.andoridFn = this.androidCallJS
      this.maxSum = 1000
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        //获取当前地址，判断结尾是.a21du还是.a21du2，并返回是否可见微信充值
        let thisHref = sessionStorage.getItem('newHref')
        if (thisHref.indexOf('com.wangrui.a21du2') == -1){
          this.isShow = true
        }else{
          this.isShow = false
        }
      }
      // console.log(this.maxSum)
    },
    methods:{
      handlerPay(){
        if(Number(this.number)>=0.01&&this.number!='') {
          // this.number.toFixed(2)
          this.number = Math.floor(this.number * 100) / 100 
          let params = {
            payment:this.payment,
            number: this.number,
            // 2 温度卡
            type:'2'
          }
          var u = navigator.userAgent;
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          if(isAndroid){
            // android
            let newStr = JSON.stringify(params)
            window.AndroidWebView.showInfoFromJs(newStr)
          }
          if(isiOS){
            // IOS
            window.webkit.messageHandlers.showMessage.postMessage(params);
          }

        }else {
          return this.$toast('请输入正确金额')
        }
     
      },
      ocToJs(val) {
        let token = this.$route.query.token
        if(val==0) {
          this.$toast('支付失败')
          this.$router.push({path:'/wdkPay',query:{token:token}})
        }else if(val==1) {
          //成功
          this.$toast('支付成功')
          this.$router.push({path:'/wdkPay',query:{token:token}})
        }else if(val==2) {
          return this.$toast('取消了支付')
        }
      },
      androidCallJS(val){
        // let token = location.hash.split('=')[1]
        let token = this.$route.query.token
        if(val==0) {
          this.$toast('支付失败')
          this.$router.push({path:'/wdkPay',query:{token:token}})
        }else if(val==1) {
          //成功
          this.$toast('支付成功')
          this.$router.push({path:'/wdkPay',query:{token:token}})
        }else if(val==2) {
          return this.$toast('取消了支付')
        }
      },
      //最大充值金额限制
      moneyBlur(){
        if(Number(this.number)>Number(this.maxSum)) {
          this.number = this.maxSum
        }
      },
      //最大可充值
      maxPay() {
        this.number = this.maxSum
      }
    },
    computed:{
      btn() {
        // console.log(val)
        if(Number(this.number)>=0.01&&this.number!='') {
          return false
        }else {
          return true
        }
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  @mixin bg-image($url) {
    background-image: url($url + "@1x.png");
    @media (-webkit-min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2) {
      background-image: url($url + "@2x.png")
    }
    @media (-webkit-min-device-pixel-ratio: 3),(min-device-pixel-ratio: 3) {
      background-image: url($url + "@3x.png")
    }
  }

  .big-box {
    padding: 16px;
    .sum {
      width: 343px;
      height: 162px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 16px;
      box-sizing: border-box;
      padding-bottom: 0;
      .text {
        font-size: 16px;
        font-weight: 400;
        color: #181830;
        margin-bottom: 48px;
      }
      .van-field {
        font-size: 32px;
        font-weight: 400;
        color: #181830!important;
        height: 36px;
        padding: 0;
      }
      ::v-deep .van-field__label {
        width: 25px;
        color: #181830;
        display: flex;
        align-items: center;
      }
      ::v-deep .van-field__control {
        font-weight: 700;
        color: #181830;
      }
      .tips {
        margin-top: 20px;
        color: #7F7F7F;
        font-size: 13px;
      }
    }
    .payment {
      display: flex;
      justify-content: space-between;
      width: 344px;
      //height: 100px;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 13px 16px;
      box-sizing: border-box;
      .left>div,.right .van-radio{
        margin: 10px 0;
      }
      .left,.right .van-radio-group  {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: #40404E;
      }
      .left {
        .alipay,.wechat {
          width: 24px;
          height: 24px;
          display: inline-block;
          margin-right: 8px;
        }
        .alipay {
          @include bg-image('../../../assets/images/alipay');
          background-size: 24px 24px;
          background-repeat: no-repeat;
        }
        .wechat {
          @include bg-image('../../../assets/images/wechat');
          background-size: 24px 24px;
          background-repeat: no-repeat;
        }
        >div {
          display: flex;
          align-items: center;
        }

      }
    }
    .van-button {
      width: 100%;
      height: 50px;
      margin-top: 24px;
      border-radius: 25px;
      color: #fff;
      font-size: 16px;
    }
    .paymen-btn {
      background-image: linear-gradient(to bottom right, #05D9FF, #02B2FF);
    }
    .input-cz {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .max-num {
        width: 80px;
        text-align: right;
        font-size: 13px;
        color: #02B2FF;
      }
    }
  }
</style>